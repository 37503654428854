"use client";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "next/navigation";

import usePosition from "@/hooks/usePosition";
import {
  embeddedSearchQuerySubmittedEvent,
  headerSearchBarSubmittedEvent,
} from "@/lib/segment";
import defaultSuggestions from "@/mocks/defaultSuggestions.json";

import ProductSearchSuggestions from "./ProductSearchSuggestions";
import { ProductSearchContextProvider } from "./ProductSearchContextProvider";
import SearchBox from "./SearchBox";
import { Suggestions } from "./types";
import {
  EventCacheType,
  getCourseSubject,
  getProgramSubject,
  resetEventCache,
} from "@/utils/tagular/helpers";
import { ProcessedCourse } from "@/lib/course/types";
import { Program } from "@/lib/program/types";
import { PacingType } from "@/lib/program/types";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
} from "@/constants/tagular/main";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { useInView } from "react-intersection-observer";

export interface ProductSearchProps {
  placeholder: string;
  button?: string;
  suggestions?: Suggestions;
  variant?: string;
  embedded?: boolean;
  showButtons?: boolean;
  isRoundedButton?: boolean;
  isSearchBoxColumn?: boolean;
  course?: ProcessedCourse;
  program?: Program;
  conversionType?: string;
  location?: string;
}

const ProductSearch = ({
  placeholder,
  button = "Search",
  suggestions = defaultSuggestions,
  variant = "",
  embedded = true,
  showButtons = true,
  isRoundedButton = true,
  isSearchBoxColumn = true,
  course,
  program,
  conversionType,
  location = "embedded-search",
}: ProductSearchProps) => {
  const params = useSearchParams();
  const [showHits, setShowHits] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const positionedDivRef = useRef<HTMLDivElement | null>(null);
  const position = usePosition(searchBoxRef, positionedDivRef);

  const webElement: ElementClicked["webElement"] = {
    elementType: ElementType.Entry,
    htmlId: "search-button",
    location: location,
    text: button,
    name: conversionType ?? "hero-search",
  };

  const { elementClicked, elementViewed } = useElementEvents({ webElement });
  const handleFocus = () => {
    resetEventCache(EventCacheType.ProductSearch);
    setShowHits(true);
  };

  const { ref: inViewEventRef, inView } = useInView(
    DEFAULT_USE_IN_VIEW_OPTIONS,
  );

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView]);

  const handleBlur = () => {
    setTimeout(() => {
      setShowHits(false);
    }, 200);
  };

  const sendAnalytics = (data?: { fromViewAllButton?: boolean }) => {
    const handler = embedded
      ? embeddedSearchQuerySubmittedEvent
      : headerSearchBarSubmittedEvent;

    elementClicked();

    handler({
      label: searchBoxRef.current?.querySelector("input")?.value,
      fromViewAllButton: data?.fromViewAllButton,
      courseUuid: course?.productUuid,
      activeCourseRunKey: course?.activeCourseRun?.key,
      ...(course && {
        isSelfPaced:
          course.activeCourseRun?.pacingType === PacingType.SELFPACED,
      }),
      subject: course ? getCourseSubject(course) : getProgramSubject(program),
      ...(program && {
        programUuid: program.externalId,
        path: program.marketingUrl,
      }),
    });
  };

  return (
    <ProductSearchContextProvider query={params.get("q") || ""}>
      <div ref={inViewEventRef} className="relative min-h-12">
        <div ref={searchBoxRef}>
          <SearchBox
            button={button}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            variant={variant}
            onSubmit={sendAnalytics}
            showButtons={showButtons}
            isRoundedButton={isRoundedButton}
            isSearchBoxColumn={isSearchBoxColumn}
          />
        </div>
        <div
          className={`${
            showHits ? "my-1" : "hidden"
          } px-3 md:px-0 md:w-[504px] absolute z-50 ${
            position === "above" ? "bottom-full" : ""
          }`}
          ref={positionedDivRef}
        >
          <ProductSearchSuggestions
            suggestions={suggestions}
            sendAnalytics={sendAnalytics}
            location={location}
          />
        </div>
      </div>
    </ProductSearchContextProvider>
  );
};

export default ProductSearch;
