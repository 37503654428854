import type { IconProps } from "@/components/icons/types";
import AccessTimeFilled from "@/components/icons/AccessTimeFilled";
import ArrowRight from "@/components/icons/ArrowRight";
import ArrowRightAlt from "@/components/icons/ArrowRightAlt";
import ArrowUpward from "@/components/icons/ArrowUpward";
import Award from "@/components/icons/Award";
import CalendarMonth from "@/components/icons/CalendarMonth";
import CalendarToday from "@/components/icons/CalendarToday";
import Certificate from "@/components/icons/Certificate";
import Check from "@/components/icons/Check";
import CheckListCheck from "@/components/icons/CheckListCheck";
import ChevronRight from "@/components/icons/ChevronRight";
import CircleCheck from "@/components/icons/CircleCheck";
import CirclePlay from "@/components/icons/CirclePlay";
import Clock from "@/components/icons/Clock";
import Close from "@/components/icons/Close";
import Compass from "@/components/icons/Compass";
import CreditCard from "@/components/icons/CreditCard";
import CustomerService from "@/components/icons/CustomerService";
import Dash from "@/components/icons/Dash";
import EdxBy2U from "@/components/icons/EdxBy2U";
import EventBusy from "@/components/icons/EventBusy";
import Facebook from "@/components/icons/Facebook";
import Filters from "@/components/icons/Filters";
import FormatQuote from "@/components/icons/FormatQuote";
import Info from "@/components/icons/Info";
import KeyboardArrowDown from "@/components/icons/KeyboardArrowDown";
import Launch from "@/components/icons/Launch";
import LibraryBooks from "@/components/icons/LibraryBooks";
import Lightbulb from "@/components/icons/Lightbulb";
import LinkedIn from "@/components/icons/LinkedIn";
import LocationOn from "@/components/icons/LocationOn";
import Mail from "@/components/icons/Mail";
import Money from "@/components/icons/Money";
import MoneyOutline from "@/components/icons/MoneyOutline";
import Monitor from "@/components/icons/Monitor";
import People from "@/components/icons/People";
import Person from "@/components/icons/Person";
import PlayCircle from "@/components/icons/PlayCircle";
import PlayCircleFilled from "@/components/icons/PlayCircleFilled";
import Program from "@/components/icons/Program";
import Reddit from "@/components/icons/Reddit";
import School from "@/components/icons/School";
import Search from "@/components/icons/Search";
import SeeLess from "@/components/icons/SeeLess";
import SeeMore from "@/components/icons/SeeMore";
import Timelapse from "@/components/icons/Timelapse";
import Trencher from "@/components/icons/Trencher";
import TrendingPoints from "@/components/icons/TrendingPoints";
import TrendingUp from "@/components/icons/TrendingUp";
import Twitter from "@/components/icons/Twitter";
import X from "@/components/icons/X";
import Warning from "@/components/icons/Warning";
import WarningCircle from "@/components/icons/WarningCircle";
import OpenQuote from "./OpenQuote";
import Speedometer from "./Speedometer";
import FacebookCircle from "./FacebookCircle";
import LinkedInCircle from "./LinkedInCircle";
import CircleCheckOutline from "./CircleCheckOutline";

const Icon = ({ svgProps, pathProps, type }: IconProps) => {
  switch (type) {
    case "access-time-filled":
      return <AccessTimeFilled svgProps={svgProps} pathProps={pathProps} />;
    case "arrow-right":
      return <ArrowRight svgProps={svgProps} pathProps={pathProps} />;
    case "arrow-right-alt":
      return <ArrowRightAlt svgProps={svgProps} pathProps={pathProps} />;
    case "arrow-upward":
      return <ArrowUpward svgProps={svgProps} pathProps={pathProps} />;
    case "award":
      return <Award svgProps={svgProps} pathProps={pathProps} />;
    case "calendar-month":
      return <CalendarMonth svgProps={svgProps} pathProps={pathProps} />;
    case "calendar-today":
      return <CalendarToday svgProps={svgProps} pathProps={pathProps} />;
    case "certificate":
      return <Certificate svgProps={svgProps} pathProps={pathProps} />;
    case "check":
      return <Check svgProps={svgProps} pathProps={pathProps} />;
    case "check-list-check":
      return <CheckListCheck svgProps={svgProps} pathProps={pathProps} />;
    case "chevron-right":
      return <ChevronRight svgProps={svgProps} pathProps={pathProps} />;
    case "circle-check":
      return <CircleCheck svgProps={svgProps} pathProps={pathProps} />;
    case "circle-check-outline":
      return <CircleCheckOutline svgProps={svgProps} pathProps={pathProps} />;
    case "circle-play":
      return <CirclePlay svgProps={svgProps} pathProps={pathProps} />;
    case "clock":
      return <Clock svgProps={svgProps} pathProps={pathProps} />;
    case "close":
      return <Close svgProps={svgProps} pathProps={pathProps} />;
    case "compass":
      return <Compass svgProps={svgProps} pathProps={pathProps} />;
    case "credit-card":
      return <CreditCard svgProps={svgProps} pathProps={pathProps} />;
    case "customer-service":
      return <CustomerService svgProps={svgProps} pathProps={pathProps} />;
    case "dash":
      return <Dash svgProps={svgProps} pathProps={pathProps} />;
    case "edx-by-2u":
      return <EdxBy2U svgProps={svgProps} pathProps={pathProps} />;
    case "event-busy":
      return <EventBusy svgProps={svgProps} pathProps={pathProps} />;
    case "facebook":
      return <Facebook svgProps={svgProps} pathProps={pathProps} />;
    case "facebook-circle":
      return <FacebookCircle svgProps={svgProps} pathProps={pathProps} />;
    case "filters":
      return <Filters svgProps={svgProps} pathProps={pathProps} />;
    case "format-quote":
      return <FormatQuote svgProps={svgProps} pathProps={pathProps} />;
    case "info":
      return <Info svgProps={svgProps} pathProps={pathProps} />;
    case "keyboard-arrow-down":
      return <KeyboardArrowDown svgProps={svgProps} pathProps={pathProps} />;
    case "launch":
      return <Launch svgProps={svgProps} pathProps={pathProps} />;
    case "location-on":
      return <LocationOn svgProps={svgProps} pathProps={pathProps} />;
    case "library-books":
      return <LibraryBooks svgProps={svgProps} pathProps={pathProps} />;
    case "lightbulb":
      return <Lightbulb svgProps={svgProps} pathProps={pathProps} />;
    case "linkedin":
      return <LinkedIn svgProps={svgProps} pathProps={pathProps} />;
    case "linkedin-circle":
      return <LinkedInCircle svgProps={svgProps} pathProps={pathProps} />;
    case "mail":
      return <Mail svgProps={svgProps} pathProps={pathProps} />;
    case "money":
      return <Money svgProps={svgProps} pathProps={pathProps} />;
    case "money-outline":
      return <MoneyOutline svgProps={svgProps} pathProps={pathProps} />;
    case "monitor":
      return <Monitor svgProps={svgProps} pathProps={pathProps} />;
    case "open-quote":
      return <OpenQuote svgProps={svgProps} pathProps={pathProps} />;
    case "people":
      return <People svgProps={svgProps} pathProps={pathProps} />;
    case "person":
      return <Person svgProps={svgProps} pathProps={pathProps} />;
    case "play-circle":
      return <PlayCircle svgProps={svgProps} pathProps={pathProps} />;
    case "play-circle-filled":
      return <PlayCircleFilled svgProps={svgProps} pathProps={pathProps} />;
    case "program":
      return <Program svgProps={svgProps} pathProps={pathProps} />;
    case "reddit":
      return <Reddit svgProps={svgProps} pathProps={pathProps} />;
    case "school":
      return <School svgProps={svgProps} pathProps={pathProps} />;
    case "search":
      return <Search svgProps={svgProps} pathProps={pathProps} />;
    case "see-less":
      return <SeeLess svgProps={svgProps} pathProps={pathProps} />;
    case "see-more":
      return <SeeMore svgProps={svgProps} pathProps={pathProps} />;
    case "speedometer":
      return <Speedometer svgProps={svgProps} pathProps={pathProps} />;
    case "timelapse":
      return <Timelapse svgProps={svgProps} pathProps={pathProps} />;
    case "trencher":
      return <Trencher svgProps={svgProps} pathProps={pathProps} />;
    case "trending-points":
      return <TrendingPoints svgProps={svgProps} pathProps={pathProps} />;
    case "trending-up":
      return <TrendingUp svgProps={svgProps} pathProps={pathProps} />;
    case "twitter":
      return <Twitter svgProps={svgProps} pathProps={pathProps} />;
    case "warning":
      return <Warning svgProps={svgProps} pathProps={pathProps} />;
    case "warning-circle":
      return <WarningCircle svgProps={svgProps} pathProps={pathProps} />;
    case "x":
      return <X svgProps={svgProps} pathProps={pathProps} />;
    default:
      return null;
  }
};

export default Icon;

export {
  AccessTimeFilled,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  Award,
  CalendarMonth,
  CalendarToday,
  Certificate,
  Check,
  CheckListCheck,
  ChevronRight,
  CircleCheck,
  CirclePlay,
  Clock,
  Close,
  Compass,
  CreditCard,
  CustomerService,
  Dash,
  EdxBy2U,
  EventBusy,
  Facebook,
  FacebookCircle,
  Filters,
  FormatQuote,
  KeyboardArrowDown,
  Launch,
  LibraryBooks,
  Lightbulb,
  LinkedIn,
  LinkedInCircle,
  LocationOn,
  Mail,
  Money,
  Monitor,
  OpenQuote,
  People,
  Person,
  PlayCircle,
  PlayCircleFilled,
  Program,
  Reddit,
  School,
  Search,
  SeeLess,
  SeeMore,
  Speedometer,
  Timelapse,
  Trencher,
  TrendingPoints,
  TrendingUp,
  Twitter,
  X,
  Warning,
  WarningCircle,
};
